<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/psppe'" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export to Excel</a> -->
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/psppe?request_type='+search.request_type+'&item_part='+search.item_part+'&requested_by='+search.requested_by+'&requested_date='+search.requested_date+'&accepted_date='+search.accepted_date+'&accepted_by='+search.accepted_by+'&for_use='+search.for_use+'&sppb_process='+search.sppb_process+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Open"
          :value="(totalOpen)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="In Progress"
          :value="(totalInProgress)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Pending"
          :value="(totalPending)"
          iconClass="fa fa-hourglass-end"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Closed"
          :value="(totalClosed)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Request For Spare Parts & External Repairs</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Request Number</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Request Type</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Item Part</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Requested Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Requested By</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Accepted Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Accepted By</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Lead Time</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Qty Submitted</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Qty Accepted</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">UOM</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">For Use</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">For Spare Part Master</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SPPB process</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PO process</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Notes</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ row.request_number }}</span>
                        <!-- <span class="badge bg-gradient-danger" v-if="row.maintenance_type == 'User Request' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-secondary" v-if="row.maintenance_type == 'Monitoring On Line' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-primary" v-if="row.maintenance_type == 'Daily Program' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-warning" v-if="row.maintenance_type == 'Program Over Time' ">{{ row.request_no }}</span> -->
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.request_type }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.item_part }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.requested_date).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.requested_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.accepted_date).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.accepted_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ leadTime(row.requested_date, row.accepted_date) }} Days</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty_submitted }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty_accepted }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.uom }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.for_use }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.for_sparepart }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.sppb_process }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.po_process }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.notes }}</span>
                      </td>
                       <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Open' ">Open</span>
                        <span class="badge bg-gradient-info" v-if="row.status == 'In Progress' ">In Progress</span>
                        <span class="badge bg-gradient-warning" v-if="row.status == 'Pending' ">Pending</span>
                        <span class="badge bg-gradient-success" v-if="row.status == 'Closed' ">Closed</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" v-if="row.status != 'Closed' "></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)" v-if="row.status != 'Closed' "></i>
                        <!-- <a style="margin-right: 20px;" :href="apiUrl+'print-pdf/print-psppe-by-id?id='+row.id+''" target="_BLANK"><i class="fa fa-print"></i></a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">

        <label for="example-text-input" class="form-control-label mt-3">Request Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="reqSparepartData.request_type">
          <option value="Spare Part">Spare Part</option>
          <option value="External Repair">External Repair</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3" v-if="reqSparepartData.request_type == 'Spare Part'">Spare Part Master </label>
        <multiselect v-model="reqSparepartData.for_sparepart" :options="tableSparepart.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Spare Part" label="component_name" track-by="component_name" :preselect-first="true" :custom-label="nameWithLang" @keyup="getSparepart" @select="setForUse()" :loading="isLoading" v-if="reqSparepartData.request_type == 'Spare Part'">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Item Part <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Item Part" v-model="reqSparepartData.item_part">
        <label for="example-text-input" class="form-control-label mt-3">Qty Submitted <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty Submitted" v-model="reqSparepartData.qty_submitted">
        <label for="example-text-input" class="form-control-label mt-3">UOM <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="UOM" v-model="reqSparepartData.uom">
        <label for="example-text-input" class="form-control-label mt-3">For Use <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="For Use" v-model="reqSparepartData.for_use">
        <!-- section penerimaan sparepart / update -->
        <label for="example-text-input" class="form-control-label mt-3">Qty Accepted</label>
        <input type="number" class="form-control" placeholder="Qty Accepted" v-model="reqSparepartData.qty_accepted">
        <label for="example-text-input" class="form-control-label mt-3">Accepted Date</label>
        <input type="date" class="form-control" placeholder="Accepted Date" v-model="reqSparepartData.accepted_date">
        <label for="example-text-input" class="form-control-label mt-3">Accepted By</label>
        <input type="text" class="form-control" placeholder="Accepted By" v-model="reqSparepartData.accepted_by">
        <!-- section penerimaan sparepart / update -->
        <label for="example-text-input" class="form-control-label mt-3">SPPB Process</label>
        <input type="text" class="form-control" placeholder="SPPB Process" v-model="reqSparepartData.sppb_process">
        <label for="example-text-input" class="form-control-label mt-3">PO Process</label>
        <input type="text" class="form-control" placeholder="PO Process" v-model="reqSparepartData.po_process">
        <label for="example-text-input" class="form-control-label mt-3">Notes</label>
        <input type="text" class="form-control" placeholder="Notes" v-model="reqSparepartData.notes">
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="reqSparepartData.status">
          <option value="Open">Open</option>
          <option value="In Progress">In Progress</option>
          <option value="Pending">Pending</option>
          <option value="Closed">Closed</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Request Type</label>
        <select class="form-select" aria-label="Default select example" v-model="search.request_type" v-on:keyup.enter="get()">
          <option value="Spare Part">Spare Part</option>
          <option value="External Repair">External Repair</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Item Part</label>
        <input type="text" class="form-control" placeholder="Item Part" v-model="search.item_part" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Requested By</label>
        <input type="text" class="form-control" placeholder="Requested By" v-model="search.requested_by" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Requested Date</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.requested_date" v-on:keyup.enter="get()"/>
        <!-- <input type="text" class="form-control" placeholder="Created Date" v-model="search.requested_date" v-on:keyup.enter="get()"> -->
        <label for="example-text-input" class="form-control-label mt-3">Accepted Date</label>
        <input type="date" class="form-control" placeholder="Accepted Date" v-model="search.accepted_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Accepted By</label>
        <input type="text" class="form-control" placeholder="Accepted By" v-model="search.accepted_by" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">For Use</label>
        <input type="text" class="form-control" placeholder="For Use" v-model="search.for_use" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">SPPB Process</label>
        <input type="text" class="form-control" placeholder="SPPB Process" v-model="search.sppb_process" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Open">Open</option>
          <option value="In Progress">In Progress</option>
          <option value="Pending">Pending</option>
          <option value="Closed">Closed</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Notes</label>
        <input type="text" class="form-control" placeholder="Notes" v-model="search.notes" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  
</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import requestSparepart from '@/services/requestSparepart.service';
import sparepartList from '@/services/sparepartList.service';

export default {
  name: "tables",
  components: {
    Card,
    ArgonButton,
    VueFinalModal,
    BasePagination,
    Multiselect,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tableSparepart: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      reqSparepartData: {},
      search: {
        request_type: '',
        item_part: '',
        requested_by: '',
        requested_date: '',
        accepted_date: '',
        accepted_by: '',
        for_use: '',
        sppb_process: '',
        status: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      isLoading: false,
      backgroundRed: null,

      totalOpen: '',
      totalInProgress: '',
      totalPending: '',
      totalClosed: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, requestSparepart.index({request_type: this.search.request_type, item_part: this.search.item_part, requested_by: this.search.requested_by, requested_date: this.search.requested_date, accepted_date: this.search.accepted_date, accepted_by: this.search.accepted_by, for_use: this.search.for_use, sppb_process: this.search.sppb_process, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page 

          context.totalOpen       = response.data.data.totalOpen
          context.totalInProgress = response.data.data.totalInProgress
          context.totalPending    = response.data.data.totalPending
          context.totalClosed     = response.data.data.totalClosed

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    nameWithLang ({ component_name, machine_name }) {
      return `${component_name} - ${machine_name}`
    },
    getSparepart(param){
      let context = this; 
      context.isLoading = true       
      Api(context, sparepartList.getSparepart({search: param.target.value})).onSuccess(function(response) {    
          context.tableSparepart.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableSparepart.data = [];
          }
      }).onFinish(function() { 
        context.isLoading = false
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.reqSparepartData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, requestSparepart.show(id)).onSuccess(function(response) {
        context.reqSparepartData               = response.data.data.data;
        context.reqSparepartData.for_sparepart = response.data.data.dataMasSpare;
        context.form.show                      = true;
        context.form.title                     = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.reqSparepartData.item_part != undefined && context.reqSparepartData.qty_submitted != undefined && context.reqSparepartData.uom != undefined && context.reqSparepartData.for_use != undefined && context.reqSparepartData.request_type != undefined && context.reqSparepartData.status != undefined) {
        formData.append('item_part', context.reqSparepartData.item_part);
        formData.append('qty_submitted', context.reqSparepartData.qty_submitted);
        formData.append('uom', context.reqSparepartData.uom);
        formData.append('for_use', context.reqSparepartData.for_use);
        formData.append('for_sparepart', (JSON.stringify(context.reqSparepartData.for_sparepart) == undefined) ? '' : JSON.stringify(context.reqSparepartData.for_sparepart));
        formData.append('qty_accepted', (context.reqSparepartData.qty_accepted == undefined) ? '' : context.reqSparepartData.qty_accepted);
        formData.append('accepted_date', (context.reqSparepartData.accepted_date == undefined) ? '' : context.reqSparepartData.accepted_date);
        formData.append('accepted_by', (context.reqSparepartData.accepted_by == undefined) ? '' : context.reqSparepartData.accepted_by);
        formData.append('sppb_process', (context.reqSparepartData.sppb_process == undefined) ? '' : context.reqSparepartData.sppb_process);
        formData.append('po_process', (context.reqSparepartData.po_process == undefined) ? '' : context.reqSparepartData.po_process);
        formData.append('notes', (context.reqSparepartData.notes == undefined) ? '' : context.reqSparepartData.notes);
        formData.append('request_type', context.reqSparepartData.request_type);
        formData.append('status', context.reqSparepartData.status);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, requestSparepart.create(formData));
      } else {
          api = Api(context, requestSparepart.update(this.reqSparepartData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, requestSparepart.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    leadTime(startDate, endDate){

      var start = moment(startDate, "YYYY-MM-DD");

      // if (endDate != '0000-00-00') {
        var end = moment(endDate, "YYYY-MM-DD");
        return moment.duration(end.diff(start)).asDays();
      // }else{
      //   var end2 = '2024-08-14';
      //   return moment.duration(end2.diff(start)).asDays();
      // }

    },
    setForUse(){
      this.reqSparepartData.for_use   = this.reqSparepartData.for_sparepart['machine_name']
      this.reqSparepartData.item_part = this.reqSparepartData.for_sparepart['component_name']
      this.reqSparepartData.uom       = this.reqSparepartData.for_sparepart['uom']
      this.reqSparepartData.status    = 'Open'
    },
    filesChange(e) {
        this.reqSparepartData.image = e.target.files[0];
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, requestSparepart.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
